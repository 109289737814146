const initialState = {
    cartItems: [],
    discountItems: {},
    backendData: {
        ticketsData: [],
        AddOnData: [],
    },
    temporarySaveAddOns: [],
    bookingJourneySteps: 0,
    bookingDate: "",
    productDetail: {},
    AddToCartDetails: [],
    UserDetail: {},
    PID: "",
    buttonLoader: false,
    isLoading: false,
    SitecoreAddOnsData: {},
    addToCartResponse: {},
    editToCartResponse: {},
    removeFromCartResponse: {},
    pageData: {},
    performanceId: {
        ticketPId: "",
        lockerPId: "",
    },
    Sitecore: [],
    itemsToBeAdd: [],
    itemsToBeUpdate: [],
    removeFromCartRequest: {},
    accordion: "",
    CartAmounts: {},
    AddProducts: [],
    UpdateProducts: [],
    maximumAddonQty: "",
    PidForEvent: "",
    actionPerformed: "",
    lockers: [],
    selectedLocker: "",
    maxAddedTickets: 0,
    maxAddedAddons: 0,
    selectPassCategory: {},
    selectedTourType: {
        productCode: "",
        name: "",
        productId: {
            Adult: "",
            Child: ""
        },
        categoryName : "",
        applicableTimeSlots : [],
        availableFrom : "",
        availableUpTo: "",
        ticketCategory: "",
        accountCategoryId: "",
        ticketprice: "",
        calendarMessage: "",
        priceMessage: "",
        ticketTemplateId: "",
        calendarBased : "",
        availableWeekDays: [],
        blockingDays : "",
    },
    categoryid: "",
    templateid: "",
    memberDetails: {},
    isProccedToPayClicked: false,
};

export const bookingJourney = (state = initialState, action) => {
    switch (action.type) {
        case "TICKETS_DATA":
            return {
                ...state,
                backendData: {
                    AddOnData: [...state.backendData.AddOnData],
                    ticketsData: action.payload,
                },
            };
        case "ADD_TO_CART_ITEM":
            return { ...state, cartItems: action.payload };
        case "APPLIED_COUPON":
            return { ...state, discountItems: action.payload };
        case "REMOVE_CART_ITEM":
            let result = state.cartItems.filter(
                (value) => value.id !== action.payload
            );
            return { ...state, cartItems: result };

        case "BOOKING_JOURNEY_STEPS_HANDLER":
            return { ...state, bookingJourneySteps: action.payload };
        case "SELECT_ACCOUNT_CATEGORY":
            return { ...state, categoryid: action.payload };
        case "SELECT_TICKET_TEMPLATE":
            return { ...state, templateid: action.payload };
        case "GET_DATE":
            return { ...state, bookingDate: action.payload };
        case "GET_PRODUCTID":
            return { ...state, productDetail: action.payload };
        case "ADD_TO_CART_DETAILS":
            return {
                ...state,
                AddToCartDetails: action.payload,
            };

        case "SAVE_USER_DETAILS":
            return { ...state, UserDetail: action.payload };
        case "IS_MATCH_PID":
            return { ...state, PID: action.payload };

        case "SAVE_ADD_ONS_DATA":
            return {
                ...state,
                backendData: {
                    ticketsData: [...state.backendData.ticketsData],
                    AddOnData: action.payload,
                },
            };

        case "SAVE_SITECORE_ADD_ONS_DATA":
            return { ...state, SitecoreAddOnsData: action.payload };

        case "SAVE_PERFORMANCEID":
            return {
                ...state,
                performanceId: {
                    ticketPId: action.payload.ticketPId,
                    lockerPId: action.payload.lockerPId,
                },
            };

        case "SAVE_PAGE_DATA":
            return { ...state, pageData: action.payload };

        case "BUTTON_LOADING":
            return { ...state, buttonLoader: action.payload };

        case "ADD_TO_CART_RESPONSE":
            return { ...state, addToCartResponse: action.payload };

        case "EDIT_TO_CART_RESPONSE":
            return { ...state, editToCartResponse: action.payload };
        case "REMOVE_FROM_CART_RESPONSE":
            return { ...state, removeFromCartResponse: action.payload };
        case "IS_LOADING":
            return { ...state, isLoading: action.payload };
        case "SAVE_SITECORE":
            return { ...state, Sitecore: action.payload };

        case "ITEMS_TO_BE_ADD":
            return { ...state, itemsToBeAdd: action.payload };

        case "ITEMS_TO_BE_UPDATE":
            return { ...state, itemsToBeUpdate: action.payload };

        case "REMOVE_FROM_CART_REQUEST":
            return { ...state, removeFromCartRequest: action.payload };
        case "ACCORDION_STATE":
            return { ...state, accordion: action.payload };

        case "SAVE_CART_AMOUNT":
            return { ...state, CartAmounts: action.payload };

        case "ADD_PRODUCTS":
            return { ...state, AddProducts: action.payload };
        case "UPDATE_PRODUCTS":
            return { ...state, UpdateProducts: action.payload };

        case "TEMPORARY_SAVE_ADD_ONS":
            return { ...state, temporarySaveAddOns: action.payload };

        case "SAVE_MAXIMUM_ADD_ON_QTY":
            return { ...state, maximumAddonQty: action.payload };

        case "SAVE_PRODUCT_ID_FOR_EVENTS":
            return { ...state, PidForEvent: action.payload };

        case "SAVE_ACTION_PERFORMED":
            return { ...state, actionPerformed: action.payload };

        case "SAVE_LOCKERS_DATA":
            return { ...state, lockers: action.payload };

        case "SAVE_SELECTED_LOCKER_DROPDOWN_VALUE":
            return { ...state, selectedLocker: action.payload };

        case "SAVE_MAX_TICKET_ADDED":
            return { ...state, maxAddedTickets: action.payload };

        case "SELECT_PASS_CATEGORY":
            return { ...state, selectPassCategory: action.payload };

        case "SELECT_TOUR_TYPE":
            return { ...state, selectedTourType: action.payload };

        case "ADD_MEMBER_DETAILS":
            return {
                ...state,
                memberDetails: {
                    ...state.memberDetails,
                    [action.payload.title]: action.payload.data,
                },
            };

        case "EMPTY_UNUSED_VALUES":
            return {
                ...state,
                cartItems: [],
                discountItems: {},
                backendData: {
                    ticketsData: [],
                    AddOnData: [],
                },
                temporarySaveAddOns: [],
                productDetail: {},
                AddToCartDetails: [],
                UserDetail: {},
                PID: "",
                SitecoreAddOnsData: {},
                addToCartResponse: {},
                editToCartResponse: {},
                removeFromCartResponse: {},
                performanceId: {
                    ticketPId: "",
                    lockerPId: "",
                },
                itemsToBeAdd: [],
                itemsToBeUpdate: [],
                removeFromCartRequest: {},
                accordion: "",
                CartAmounts: {},
                AddProducts: [],
                UpdateProducts: [],
                maximumAddonQty: "",
                PidForEvent: "",
                actionPerformed: "",
                lockers: [],
                selectedLocker: "",
                categoryid: "",
                templateid: "",
                maxAddedTickets: 0,
                maxAddedAddons: 0,
                memberDetails: {},
                isProccedToPayClicked: false,
            };

        case "RESET_BOOKING_JOURNEY_REDUCER":
            return initialState;

        case "IS_PROCEED_TO_PAY_CLICKED":
            return { ...state, isProccedToPayClicked: action.payload };

        default:
            // return { ...state };
            return state;
    }
};
