import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";
let restService = new RestService();

export const accountService = async (payload) => {
  let serviceType = RestConfig.find((data) => data.type === "ACCOUNT").endpoint;

  let request = {
    requestType: "postAccount",
    data: {
      type: serviceType,
      requestParam: payload,
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        console.log("accountService res", res);
        if (res.status === 200 && res.data.data) {
          resolve(res.data.data);
        }
      })
      .catch((error) => {
        console.log("accountService error", error);
        console.log(error);
        reject(error);
      });
  });
};
