import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { bookingDetailsReducer } from "./bookingDetailsReducer";
import { bookingJourney } from "./bookingJourney";

export default combineReducers({
  appDetails: appReducer,
  bookingDetailsV2: bookingDetailsReducer,
  bookingJourney: bookingJourney,
});
