export const GOOGLE_MAP_DIR_URL = "https://www.google.com/maps/dir/?api=1&";
export const COOKIE_KEYS = {
    cookieConsent: "cookieConsent",
};
export const UNDER_MAINTENANCE = "false";

export const TOURTYPEID = {
    package: "ec271da9-35d9-4466-81fe-2e3900c467bf",
    offers: "6a372477-d65b-458c-8ea8-7d550a3ce89f",
};
export const paymentStyles = {
    main: {
        height: 260,
    } /* the style for the main wrapper div around the card input*/,
    input: {
        background: "none",
        color: "#003e7e !important",
        fontSize: "18px",
        padding: "10px 10px 10px 5px",
        display: "block",
        borderRadius: 0,
        borderBottom: "1px solid #003e7e !important",
        borderStyle: "solid !important",
        borderColor: "#003e7e !important",
        fontFamily: "amaranthregular",
    } /* custom style options for the input fields */,
    invalid: {
        background: "none",
        color: "red",
        fontSize: "18px",
        padding: "10px 10px 10px 5px",
        display: "block",
        borderRadius: 0,
        borderBottom: "1px solid red !important",
        borderStyle: "solid !important",
        borderColor: "red !important",
        fontFamily: "amaranthregular",
    } /* custom input invalid styles */,
};

export const SchoolOutingsEmail = "studentgroups@dhentertainment.ae";
// export const SchoolOutingsEmail = "Dilen.Raval-v@dhentertainment.ae";
export const PickAPark = "Wild wadi";

export const OnlyTickets = [
    //   PROD Tickets
    "45F06D6F-211C-3C2C-7DF7-018798E5F741", //ALL-INCLUSIVE F&B (for non-residents) (Adult)
    "F4BE8567-E837-482D-16DD-018798F71EC7", //ALL-INCLUSIVE F&B (for non-residents) (Child)
    "1569D669-73BC-98BC-14D9-018798F47631", //ALL-INCLUSIVE F&B (for UAE residents) (Adult)
    "BBBD8589-8F1B-9C54-207E-0187990367A8", //ALL-INCLUSIVE F&B (for UAE residents) (Child)
    "CCFE890E-799F-5F2A-7336-017F508F129F", //DAY PASS (for non-residents) (Adult)
    "B22CCAEC-7B23-6F36-7370-017F508F1630", //DAY PASS (for non-residents) (Child)
    "35066AC9-F13C-3EB6-74A4-017F508F4AE6", //DAY PASS (for UAE residents) (Adult)
    "AAE7BFCC-2BC5-D91E-7660-017F509257D1", //DAY PASS (for UAE residents) (Child)
    "1F2B8F5F-87B0-585A-1D25-0188E2569E96", //Annual Pass - Gold
    "0657CF38-97BA-3119-1F9B-0188E258FE71", //Annual Pass - Platinum
    "7DD1B174-E2A0-1ACC-2104-0188E25AC9BE", //Annual Pass - Silver
    "C44FB069-3B0F-4DAF-2396-018FAA8B1529", //Flash Sale
    "12AFD6D4-B84A-8FB1-78BC-018FA4808BAF", //Abu Dhabi Municipality Offer
    "12AFD6D4-B84A-8FB1-78BC-018FA4808BAF", //Abu Dhabi Municipality Offer
    "6CE8F99E-A31B-890E-5AE3-018FA477E3B3", //HSBC Card Holder Offer
    "9A57071A-264C-07AC-7744-0188E31DAC7E", //VISA Monday
    "5C8F1C18-62B2-BC53-1F3E-018B50DB3B55", //Private Cabana
    //   UAT Tickets
    "A8372B02-4AB2-6BE0-74A5-018707A1B81C", //ALL-INCLUSIVE F&B (for non-residents) (Adult)
    "AE57AF7E-8B72-55AA-767F-018707A90534", //ALL-INCLUSIVE F&B (for non-residents) (Child)
    "4340AAB6-2FEC-5183-21EF-018774C9CDA9", //ALL-INCLUSIVE F&B (for UAE residents) (Adult)
    "26BB9098-3A89-86AC-2502-018774DF9392", //ALL-INCLUSIVE F&B (for UAE residents) (Child)
    "3E00A0BA-F8C8-4F5E-79B9-017E2A703421", //DAY PASS (for non-residents) (Adult)
    "A1D257F3-C570-11E6-0333-017E2A76E2AC", //DAY PASS (for non-residents) (Child)
    "B9FC505D-8D84-CA68-07C7-017EC33E39F1", //DAY PASS (for UAE residents) (Adult)
    "A1334C33-7AF6-CC0D-212C-017ED30A7909", //DAY PASS (for UAE residents) (Child)
    "83A0D3B6-2005-3DD2-3889-0188D8DDDD56", //Annual Pass - Gold
    "C9B4308E-3DDF-251D-39DE-0188D8DEC696", //Annual Pass - Platinum
    "5946AFBD-1FAE-8349-375E-0188D8DCD88A", //Annual Pass - Silver
    "163B49F1-F13B-734D-47BC-017F1C38D572", //Online DH Executive Guest
    "66840D09-8985-C681-44F6-017F1C3155EE", //Online DH Executive 1.1m Above
];

export const OnlyAddons = [
    //   PROD Addons
    "FBAAB338-2C08-102C-1BE6-018797AD6D7E", //All You Can Eat (Adult)
    "267D4925-2869-E1A9-1DDB-018797B5362D", //All You Can Eat (Child)
    "E1E16C43-CE2E-BC67-3B12-017F7ECD0534", //Fast Pass

    //   UAT Addons
    "5737E146-348A-59D9-6929-0186BBEB9A75", //All You Can Eat (Adult)
    "96C78A57-4C1E-B48E-56CA-0186C0A8A836", //All You Can Eat (Child)
    "74C933FD-62CB-8DEE-2FAF-017F0D54646E", //Fast Pass
];

export const OnlyLockers = [
    //   PROD Tickets
    "DEE8A118-931C-3E05-0CEA-017F79EA75D5", //Locker Female Large
    "AEE6D83B-D768-0F99-0D11-017F79EA76D5", //Locker Female Medium
    "6CAAD82E-947C-FCBB-0D35-017F79EA77E6", //Locker Female Small
    "96751E4D-A83A-904E-0D5B-017F79EA7D40", //Locker Male Large
    "2B3D1821-0F60-B676-06C9-017F79E5DE2D", //Locker Male Medium
    "26CD51DF-080B-F0DA-0AC6-017F79E84678", //Locker Male Small

    //   UAT Tickets
    "330DE7E2-EA22-868B-64B7-017E8C7F2369", //Locker Female Large
    "BBB83691-ADE8-601C-62A5-017E8C7E9332", //Locker Female Medium
    "9A17A15D-9224-ECDB-7CF0-017E4DB46FA6", //Locker Female Small
    "907D9F4C-FBDD-5011-63B2-017E8FD616D1", //Locker Male Large
    "DE40DB36-2D49-BEE1-6234-017E8FD5720D", //Locker Male Medium
    "6133CC20-2AB7-A0DA-543D-017E529140D9", //Locker Male Small
];

export const TicketCategoryList = {
    package: "ec271da9-35d9-4466-81fe-2e3900c467bf",
    offers: "6a372477-d65b-458c-8ea8-7d550a3ce89f",
    visamonday: "53cee67f-041c-4c75-91c0-d5c87ef46f20",
    omannational: "7a9c46d6-73b4-48c6-b4ca-d00aa4a6d088",
    annualPass: "766af308-31fd-4170-895a-627b3f54b557",
    roadShow: "E5A6C6B4-F0C5-4ECD-88A8-6C00394EE9E5",
    employeeoffers: "0f028275-7a0d-4371-9515-d6d01c5cf353",
    cabana: "e16c96e4-1a64-4389-851e-ffb0de9fa4f1",
    uaeannualPass: "d9bd8a73-20dc-4b0a-97f7-838bd459362a",
};


