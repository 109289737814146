import RestService from "../providers/service";
import RestConfig from "../providers/restConfig.json";

let restService = new RestService();

export const createMemberAccount = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "CREATEMEMBERACCOUNT"
  ).endpoint;

  let request = {
    requestType: "createMemberAccount",
    data: {
      type: serviceType,
      requestParam: {
        members: payload,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
