const initialState = {
  page_type_context: "",
  page_cta_context: "",
  path_context: "",
  pageLanguage: "",
};

const appReducer = (state = initialState, action = {}) => {
  if (action.type === "PAGE_TYPE_CONTEXT") {
    return {
      ...state,
      page_type_context: action.payload,
    };
  }
  if (action.type === "PAGE_CTA_CONTEXT") {
    return {
      ...state,
      page_cta_context: action.payload,
    };
  }
  if (action.type === "PATH_CONTEXT") {
    return {
      ...state,
      path_context: action.payload,
    };
  }
  if (action.type === "PAGE_LANGUAGE") {
    return {
      ...state,
      pageLanguage: action.payload,
    };
  } else {
    return state;
  }
};
export { appReducer };
export default appReducer;
