import React from 'react';
import "./Error500.scss";
import dictionary from "i18next";


//500 internal server error page

const Error500 = (props) => (

    <div className="error-500-container">
        <div className="error-500-message">

            <h1>{dictionary.t("ErrorPage-DownNotOut", "DOWN BUT NOT OUT")}</h1>
            <p dangerouslySetInnerHTML={{ __html: dictionary.t("ErrorPage-TechnicalProblem", "We are experiencing a technical problem at the moment and sincerely apologize for any inconvenience this might cause you\r\n\r\n    Please try again shortly or contact our booking team at <a href=\"mailto:reservations@jumeirah.com\"\r\n\r\n               title=\"reservations@jumeirah.com\" >\r\n\r\n                    reservations@jumeirah.com</a> or <a href=\"tel:+971 4 364 7555\">+971 4 364 7555</a>. Thank you.") }}>


            </p>
            <div className="error-link"> <a href={`/${window.location.pathname.substring(1, 3)}/contact/contact-us`}>{dictionary.t("ErrorPage-NeedHelpText", "ErrorPage-NeedHelpText")}</a></div>

        </div>
    </div>

);

export default Error500;
