import React from 'react'
import "./appLoader.scss"
const AppLoader = ({ delayTime }) => {

    return (
        <div className="page-loader">
            <div className="loader-container">
                <div className="wave-fill">
                    <div className="water" style={{ animation: delayTime && 'water 7s ease infinite' }}>
                        <span className="wave"></span>
                        <span className="deep-water"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppLoader