import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const pnrBeforeNIService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "BEFORETRANSACTION"
  ).endpoint;

  let request = {
    requestType: "PNRNumberBeforeNI",
    data: {
      type: serviceType,
      requestParam: {
        ShopCartId: payload.ShopCartId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let pnrData = {};
        if (res.status === 200 && res.data.data.SaleCode) {
          pnrData = {
            saleCode: res.data.data.SaleCode,
            paymentStatus: false,
            saleId: res.data.data.SaleId,
            transactionId: res.data.data.TransactionId,
          };
          resolve(pnrData);
        } else {
          throw "Pnr not created ";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const pnrAfterNIService = async (
  paymentStatus,
  payload,
  shopCartId,
  pageData,
  passCategory,
  templateId,
) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "TRANSACTION"
  ).endpoint;

  let request = {
    requestType: "PNRNumber",
    data: {
      type: serviceType,
      requestParam: {
        emailAddress: payload.mainGuestDetails.email.value,
        ShopCartId: shopCartId,
        paymentAmount: paymentStatus.paymentAmount,
        isCouponApplied: payload.pnrInfo.saleCode === "" ? true : false,
        saleCode: payload.pnrInfo.saleCode,
        authorizationCode: paymentStatus.authorizationCode,
        cardHolderName: paymentStatus.cardholderName,
        cardType: paymentStatus.cardType,
        cardNumber: paymentStatus.cardNumber,
        passCategory: passCategory,
        templateId: templateId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let pnrAfterData = {};
        if (res.status === 200 && res.data.data) {
          pnrAfterData = {
            saleCode: res.data.data.saleCode,
            saleId: res.data.data.saleId,
            pahDownloadUrl: res.data.data.pahDownloadUrl,
            transactionId: res.data.data.transactionId,
            transactionDetails: res.data.data.transactionDetails,
          };
        }

        resolve(pnrAfterData);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deletePNRService = async (SaleId) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "DELETEPNR"
  ).endpoint;

  let request = {
    requestType: "deletePNRNumber",
    data: {
      type: serviceType,
      requestParam: {
        SaleId: SaleId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        // console.log("DELETE PNR", res);

        resolve(res.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
