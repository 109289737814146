import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";
import { pdfService } from "./pdfService";
import { deletePNRService } from "./pnrService";

let restService = new RestService();

export const paymentStatusService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "PAYMENTSTATUS"
  ).endpoint;
  let request = {
    requestType: "getNiPaymentStatus",
    data: {
      type: serviceType,
      requestParam: {
        ref: payload.paymentInfo.ref,
        outletReference: payload.outletReference,
        SaleCode: payload.pnrInfo.saleCode,
        ShopCartId: payload.ShopCartId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let paymentStatusData = {};
        if (
          res.status === 200 &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data._embedded.payment[0].state === "CAPTURED"
        ) {
          let paymentStatus = res.data.data.data._embedded.payment[0];
          paymentStatusData = {
            shopCartId: res.data.data.ShopCartId,
            cardPayload: {
              state: paymentStatus.state,
              paymentStatus: paymentStatus,
              paymentAmount: paymentStatus.amount.value / 100,
              authorizationCode: paymentStatus.authResponse.authorizationCode,
              cardholderName: paymentStatus["paymentMethod"].cardholderName,
              cardType: paymentStatus["paymentMethod"].name,
              cardNumber: paymentStatus["paymentMethod"].pan,
            },
          };

          resolve(paymentStatusData);
        } else {
          throw { status: 500, state: "FAILED" };
        }
      })
      .catch(async (error) => {
        // Delete PNR Call if Payment fails
        let deletePNR = await deletePNRService(payload.pnrInfo.saleId);
        console.log("deletePNR", deletePNR);
        reject(error);
      });
  });
};

export const cardPaymentService = async (
  payload,
  pnrBeforeNIResponse = { saleCode: "", saleId: "" }
) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "CARDPAYMENT"
  ).endpoint;
  let request = {
    requestType: "cardPayment",
    data: {
      type: serviceType,
      outletId: payload.outletReference,
      sessionId:
        payload.paymentMethod == "Apple_Pay" ? "Apple_Pay" : payload.sessionId,
      ShopCartId: payload.ShopCartId,
      data: {
        outletReference: payload.outletReference,
        saleCode: payload.saleCode,
        orderPayload: {
          action: "SALE",
          amount: {},
          // payment_method: payload.paymentMethod,
          emailAddress: payload.mainGuestDetails.email.value,
          language: "en",
          merchantAttributes: {
            // redirectUrl:
            //   "https://ibaadxpuatui-slot2.azurewebsites.net/booking/booking-confirmation",

            redirectUrl:
              window.location.origin.replace("localhost", "127.0.0.1") +
              `/${window.location.pathname.substring(
                1,
                3
              )}/booking/booking-confirmation`,
            skipConfirmationPage: true,
          },
          billingAddress: {
            firstName: payload.mainGuestDetails.firstName.value,
            lastName: payload.mainGuestDetails.lastName.value,
          },
          merchantDefinedData: {
            email: payload.mainGuestDetails.email.value,
            shopCartId: payload.ShopCartId,
            saleId: pnrBeforeNIResponse.saleId
              ? pnrBeforeNIResponse.saleId
              : "",
          },
        },
      },
    },
  };
  if (payload.paymentMethod == "Apple_Pay") {
    // This is for APPLE PAY
    // return new Promise(async (resolve, reject) => {
    //   await restService
    //     .onePostService(request)
    //     .then((res) => {
    //       console.log("responce Order", res);
    //       let paymentData = {};
    //       if (res.status === 200 && res.data.data.reference) {
    //         console.log("nw res", res);
    //         paymentData = {
    //           paymentURL: res.data.data._links.payment.href,
    //           ref: res.data.data.reference,
    //           applePaypaymentURL:
    //             res.data.data._embedded.payment[0]._links["payment:apple_pay"]
    //               .href,
    //           // samsungPaypaymentURL: res.data.data._embedded.payment[0]._links["payment:samsung_pay"].href,
    //           paymentRef: res.data.data._embedded.payment[0].reference,
    //           wallet_token: res.data.wallet_token,
    //           payment_amount: res.data.data.amount.value / 100,
    //           outletId: res.data.data.outletId,
    //         };
    //         resolve(paymentData);
    //       } else {
    //         reject(paymentData);
    //         // throw "error creating payment";
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       reject(error);
    //     });
    // });
  } else {
    return new Promise(async (resolve, reject) => {
      await restService
        .onePostService(request)
        .then(async (res) => {
          let paymentData = {};

          if (
            res.status === 200 &&
            res.data &&
            res.data.data.reference &&
            res.data.data.state != "FAILED"
          ) {
            let width;
            let height;
            if (res.data.data.state == "AWAIT_3DS") {
              document.getElementById("3ds_iframe").style.display = null;
              document.getElementById("root").style.display = "none";
              width = document.getElementById("3ds_iframe").clientWidth;
              height = document.getElementById("3ds_iframe").clientHeight;
            }

            //3D CARDS
            await window.NI.handlePaymentResponse(res.data.data, {
              mountId: "3ds_iframe",
              style: {
                width: width,
                height: height,
                overflow: "scroll",
              },
            })
              .then(function (response) {
                var status = response.status;
                if (
                  status === window.NI.paymentStates.AUTHORISED ||
                  status === window.NI.paymentStates.CAPTURED
                ) {
                  // Same as before this signals successful payment
                  paymentData = {
                    ref: res.data.data.orderReference,
                    outletId: res.data.data.outletId,
                  };
                  resolve(paymentData);
                } else if (
                  status === window.NI.paymentStates.FAILED || // A new state to look out for is 3DS Challenge failure
                  status === window.NI.paymentStates.THREE_DS_FAILURE ||
                  status === "POST_AUTH_REFER"
                ) {
                  // payment failure signal
                  document.getElementById("root").style.display = null;
                  paymentData = {
                    ref: res.data.data.orderReference,
                    outletId: res.data.data.outletId,
                  };
                  reject(paymentData);
                }
              })
              .catch((err) => {
                console.log("Cardpayment fail", err);
                reject(err);
              });
          } else {
            document.getElementById("root").style.display = null;
            throw "error creating payment";
          }
        })
        .catch((error) => {
          console.log("Card payment service res 1", error);
          console.log(error);
          reject(error);
        });
    });
  }
};


export const paymentService = async (
    payload,
    pnrBeforeNIResponse = { saleCode: "", saleId: "" }
) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "NIPAYMENT"
    ).endpoint;

    console.log("Selected Payment Method ", payload.paymentMethod);

    let request = {
        requestType: "niPayments",
        data: {
            type: serviceType,
            data: {
                outletReference: payload.outletReference,
                orderPayload: {
                    action: "SALE",
                    amount: {
                        currencyCode: "AED",
                        value: payload.cartBase.totalAmount * 100,
                    },
                    // payment_method: payload.paymentMethod,
                    emailAddress: payload.mainGuestDetails.email.value,
                    language: "en",
                    merchantAttributes: {
                        redirectUrl:
                            window.location.origin.replace("localhost", "127.0.0.1") +
                            `/${window.location.pathname.substring(
                                1,
                                3
                            )}/tour-booking/booking-confirmation`,
                        skipConfirmationPage: true,
                    },
                    billingAddress: {
                        firstName: payload.mainGuestDetails.firstName.value,
                        lastName: payload.mainGuestDetails.lastName.value,
                    },
                    merchantDefinedData: {
                        email: payload.mainGuestDetails.email.value,
                        shopCartId: payload.cartBase.shopCartId,
                        saleCode: pnrBeforeNIResponse.saleCode
                            ? pnrBeforeNIResponse.saleCode
                            : "",
                        saleId: pnrBeforeNIResponse.saleId
                            ? pnrBeforeNIResponse.saleId
                            : "",
                    },
                },
            },
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then((res) => {
                console.log("responce Order", res);
                let paymentData = {};

                if (res.status === 200 && res.data.data.reference) {
                    console.log("nw res", res);
                    paymentData = {
                        paymentURL: res.data.data._links.payment.href,
                        ref: res.data.data.reference,
                        applePaypaymentURL:
                            res.data.data._embedded.payment[0]._links["payment:apple_pay"]
                                .href,
                        // samsungPaypaymentURL: res.data.data._embedded.payment[0]._links["payment:samsung_pay"].href,
                        paymentRef: res.data.data._embedded.payment[0].reference,
                        wallet_token: res.data.wallet_token,
                        payment_amount: res.data.data.amount.value / 100,
                        outletId: res.data.data.outletId,
                    };
                    resolve(paymentData);
                } else {
                    reject(paymentData);
                    // throw "error creating payment";
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export const samsungPayService = async (payload) => {
    let serviceType = RestConfig.find(
        (data) => data.type === "SAMSUNGPAY"
    ).endpoint;

    console.log("samsungPay console ", payload);

    let request = {
        requestType: "samsungpay",
        data: {
            type: serviceType,
            data: payload,
        },
    };

    return new Promise(async (resolve, reject) => {
        await restService
            .onePostService(request)
            .then((res) => {
                console.log("responce Order", res);
                // let paymentData = {};
                if (res.status === 200 && res.data.status != 500) {
                    console.log("sumsung res", res);
                    resolve(res);
                } else {
                    reject(res);
                    // throw "error creating payment";
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};

export function downloadPDF() {
  return async (dispatch, getState) => {
    const { bookingDetailsV2 } = getState();

    let requestPayload = {
      downloadUrl: bookingDetailsV2.pnrInfo.pahDownloadUrl,
    };

    return new Promise(async (resolve, reject) => {
      await pdfService(requestPayload)
        .then((pdfServiceResponse) => {
          resolve(pdfServiceResponse);
        })
        .catch((err) => {
          reject(err);
          console.log(err, "pdf Download error");
        });
    });
  };
}
