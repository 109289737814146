import axios from "axios";

const serviceList = [
  {
    type: "niPayments",
    url: "/wwService/nipayment",
  },
  {
    type: "cardPayment",
    url: "/wwService/cardPayment",
  },
  {
    type: "postAccount",
    url: "/wwService/account",
  },
  {
    type: "couponToCart",
    url: "/wwService/couponToCart",
  },

  {
    type: "addToCart",
    url: "/wwService/addToCart",
  },
  {
    type: "editItemQuantity",
    url: "/wwService/editItemQuantity",
  },
  {
    type: "getNiPaymentStatus",
    url: "/wwService/getNiPaymentStatus",
  },
  {
    type: "contactUs",
    url: "/wwService/sendContactEmail",
  },
  {
    type: "PNRNumber",
    url: "/wwService/PNRNumber",
  },

  {
    type: "PNRNumberBeforeNI",
    url: "/wwService/PNRNumberBeforeNI",
  },
  {
    type: "deletePNRNumber",
    url: "/wwService/deletePNRNumber",
  },
  {
    type: "login",
    url: "/wwService/login",
  },
  {
    type: "getPDF",
    url: "/wwService/getPDF",
  },
  { type: "getTickets", url: "/wwService/getTickets" },
  { type: "removeItemFromCart", url: "/wwService/removeItemFromCart" },
  { type: "emptyShopCart", url: "/wwService/emptyShopCart" },
  { type: "setItemDetails", url: "/wwService/setItemDetails" },
  { type: "createMemberAccount", url: "/wwService/createMemberAccount" },
];

const serviceURL = (requestType) => {
  let Path;
  let serviceType = serviceList.filter(
    (service) => service.type === requestType
  );
  if (process.env["NODE_ENV"] === "development") {
    Path = process.env.REACT_APP_DEVELOPMENT_PATH + serviceType[0].url;
  } else {
    Path = serviceType[0].url;
  }
  return Path;
};

export default class restService {
  async onePostService(param) {
    let servicePath = serviceURL(param.requestType);
    return axios({
      url: servicePath,
      method: "POST",
      data: param.data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
