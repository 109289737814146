import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const addtoCartService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "ADDTOCART"
  ).endpoint;

  let request = {
    requestType: "addToCart",
    data: {
      type: serviceType,
      requestParam: payload,
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        if (res.status === 200 && res.data.data) {
          resolve(res.data.data);
        } else {
          throw "Error adding to cart ";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const removeItemFromCart = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "REMOVEITEMFROMCART"
  ).endpoint;

  let request = {
    requestType: "removeItemFromCart",
    data: {
      type: serviceType,
      requestParam: payload,
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let cartData = {};

        if (res.status === 200 && res.data.data) {
          resolve(res.data.data);
        } else {
          throw "Error remove from cart ";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const editCartService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "REMOVETOCART"
  ).endpoint;

  let request = {
    requestType: "editItemQuantity",
    data: {
      type: serviceType,
      requestParam: payload,
    },
  };
  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let editCartData = {};
        if (res.status === 200 && res.data.data) {
          resolve(res.data.data);
        } else {
          throw "error editing cart";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
