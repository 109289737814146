import RestService from "../providers/service";
import RestConfig from "../providers/restConfig";

let restService = new RestService();

export const couponCodeService = async (payload) => {
  let serviceType = RestConfig.find(
    (data) => data.type === "COUPONTOCART"
  ).endpoint;

  let request = {
    requestType: "couponToCart",
    data: {
      type: serviceType,
      requestParam: {
        couponCode: payload.couponCode,
        ShopCartId: payload.ShopCartId,
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    await restService
      .onePostService(request)
      .then((res) => {
        let couponData = {};
        if (res.status === 200 && res.data.data.isCouponApplied === true) {
          couponData = {
            ShopCartId: res.data.data.data.ShopCartId,
            TotalDiscount: res.data.data.data.TotalDiscount,
            TotalTax: res.data.data.data.TotalTax,
            TotalAmount: res.data.data.data.TotalAmount,
            Items: res.data.data.data.Items,
            SubTotal: res.data.data.data.SubTotal,
          };
          resolve(couponData);
        } else {
          throw "Coupon cannot be applied";
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
