import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "./assets/app.css";
import dictionary from "i18next";
import CloseIcon from "./assets/IconClose.svg";
import PageMetaData from "./library/baaShared/PageMetaData";
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { fields } = route;
  const closePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const hasShownPopup = localStorage.getItem("hasShownPopup");
    let isShowDisclaimer =
      fields &&
      Object.keys(fields).length > 0 &&
      fields["Show Disclaimer"] &&
      fields["Show Disclaimer"].value;
    if (fields && Object.keys(fields).length > 0 && fields["Show Disclaimer"]) {
      localStorage.removeItem("persist:root");
    }
    if (isShowDisclaimer == true && !hasShownPopup) {
      localStorage.setItem("hasShownPopup", true);
      setShowPopup(true);
    }
  }, []);
  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <PageMetaData route={route} />

      {/* <div className={"Page-layout"+ (route.fields.Title.value !== "Home" ? ' not-homepage':' homepage')}>
    <Placeholder name="hcjss-header" rendering={route} />
	  <Placeholder name="hcjss-main" rendering={route} />
	  <Placeholder name="hcjss-footer" rendering={route} />
    </div> */}

      <Placeholder name="Header" rendering={route} pageData={route} />
      <main className="page-wrapper">
        <div className="page-content">
          <Placeholder name="HeroBanner" rendering={route} pageData={route} />
          <Placeholder name="Main" rendering={route} pageData={route} />
        </div>
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button onClick={closePopup} className="close-icon">
                <img src={CloseIcon} />
              </button>
              <h2>Note:</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: dictionary.t("Home-Disclaimer"),
                }}
              ></p>
            </div>
          </div>
        )}
      <Placeholder name="Footer" rendering={route} />
      </main>

      {/* <div className="page-wrapper">
      <Placeholder name="Header" rendering={route} pageData={route} />
      <Placeholder name="HeroBanner" rendering={route} pageData={route} />
      <Placeholder name="Main" rendering={route} pageData={route} />
      <Placeholder name="Footer" rendering={route} />
    </div> */}
    </React.Fragment>
  );
};

export default Layout;
