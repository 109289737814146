import * as Action from "./bookingDetailsActions.js";

const initialState = {
  pageLanguage: "en",
  cartBase: {
    baseProducts: [],
    shopCartId: "",
    performanceId: "",
    items: [],
    subTotal: "",
    totalTax: "",
    totalAmount: "",
    totalDiscount: "",
  },
  couponCode: "",
  dateofVisit: "",
  performanceId: "",
  mainGuestDetails: {},
  pnrInfo: {
    saleCode: "",
    paymentStatus: false,
    saleId: "",
    transactionId: "",
    pahDownloadUrl: "",
  },
  outletReference: "",
  categoryid : ""
};

export const bookingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Action.UPDATE_BOOKINGDETAILS_BYKEY: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case Action.RESET_BOOKINGDETAILS_BYKEY: {
      return {
        ...state,
        [action.payload]: { ...initialState[action.payload] },
      };
    }
    default:
      return state;
  }
};
